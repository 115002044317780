<template>
  <div :class="$style.container">
    <transition name="fade-fast">
      <div v-if="reply" :class="$style.reply">
        <p :class="$style.text">
          {{ $t('replyingTo') }}
          <span :class="$style.name">{{ name || $t('anonymous') }}</span>
        </p>
        <v-button :class="$style.cancel" color="transparent-light" @click="$emit('cancel')">
          <icon name="close" :class="$style.icon" />
          <p :class="$style.text">{{ $t('cancel') }}</p>
        </v-button>
      </div>
    </transition>
    <div :class="$style.content">
      <v-avatar
        :class="$style.avatar"
        :src="src"
        :srcset="srcset"
        alt=""
        draggable="false"
        no-progress
      />
      <message-input
        :focus="isCommentReply"
        :placeholder="$t('writeComment')"
        @send="onPostComment"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VButton from '@elements/VButton.vue'
import MessageInput from '@elements/inputs/list/MessageInput.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'

import { MessageMode } from '@common-types/chat/chat-message'

export default Vue.extend({
  name: 'VCommentInput',
  components: { MessageInput, VButton, VAvatar },
  props: {
    reply: {
      type: Object,
      default: () => ({}),
    },
    isSub: Boolean,
  },
  computed: {
    ...mapGetters('Comments', ['isCommentReply']),
    ...mapGetters('Post', ['postId']),
    ...mapGetters('Client', ['clientAvatar', 'isClientOwner']),
    ...mapGetters('App', ['appAvatarSrc', 'appAvatarSrcset', 'appId']),
    src() {
      return this.isClientOwner ? this.appAvatarSrc() : this.clientAvatar('content_url')
    },
    srcset() {
      return this.isClientOwner ? this.appAvatarSrcset() : this.clientAvatar('preview_url')
    },
    name() {
      return this.reply?.client?.name ?? null
    },
  },
  methods: {
    ...mapActions('Comments', ['postComment']),
    async onPostComment(text) {
      const postId = this.reply?.id ?? this.postId
      const modeParams = this.isClientOwner
        ? { mode: MessageMode.APP, mode_app_id: this.appId() }
        : {}
      const params = { text, post_id: postId, ...modeParams }
      await this.postComment({ params, isSub: this.reply })
      this.$emit('send')
    },
  },
})
</script>
<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 42px 1fr;
  padding: 12px;
  column-gap: 9px;
  background: #ffffff;
  box-shadow: inset 0 1px 0 $secondary-light;
}

.reply {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  background: $shadow;
  box-shadow: inset 0 1px 0 $secondary-light;

  & .text {
    font-weight: 400;
    font-size: 12px;

    & .name {
      font-weight: 500;
    }
  }

  & .cancel {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 6px;

    & .icon {
      align-self: center;
      width: 12px;
      height: 12px;
    }
  }
}

.avatar {
  align-self: center;
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 100%;
}
</style>
