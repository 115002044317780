<template>
  <transition name="bottom">
    <section
      :class="[$style.section, isIOS && $style.ios, !isSub && $style.main]"
      :key="isSub ? 'sub-comments' : 'comments'"
    >
      <div :class="[$style.content, !isSub && $style.main]" ref="content">
        <VComment v-if="postBody && !isSub" is-main-comment />
        <VGrid :length="dataLength" :is-infinite="isInfinite" @infinite="onGetComments">
          <VComment
            v-for="comment in data"
            :key="comment.id"
            :comment="comment"
            :is-sub="isSub"
            @reply="onReply"
            @expand="onExpand"
          />
          <template v-if="isSub && !pageLoading">
            <transition name="fade" appear>
              <VButton
                v-if="getThreadHasMore(commentId)"
                size="md"
                color="transparent"
                :class="$style.more"
                :is-loading="pageLoading"
                @click="onGetComments"
              >
                {{ $t('more.close') }}
              </VButton>
            </transition>
          </template>
        </VGrid>
      </div>
      <VCommentInput
        v-if="!isSub"
        :reply="reply"
        :is-sub="isSub"
        focus
        @send="onSend"
        @cancel="onCancel"
      />
    </section>
  </transition>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import VComment from '@layouts/comments/VComment.vue'
import VCommentInput from '@layouts/comments/VCommentInput.vue'
import VButton from '@elements/VButton.vue'
import Config from '@config/index'

export default Vue.extend({
  name: 'VComments',
  components: { VGrid, VComment, VCommentInput, VButton },
  props: { isSub: Boolean, commentId: Number },
  data() {
    return {
      reply: null,
    }
  },
  computed: {
    ...mapGetters('Comments', [
      'comments',
      'commentsLength',
      'getThreadPage',
      'commentsPage',
      'getSubComments',
      'getSubCommentsLength',
      'getThreadHasMore',
      'getThreadPage',
      'selectedCommentParams',
    ]),
    ...mapGetters('Page', ['pageLoading', 'isIOS']),
    ...mapGetters('Post', ['post', 'postId', 'postBody']),
    isInfinite() {
      return !this.isSub && !!this.post
    },
    data() {
      return this.isSub ? this.getSubComments(this.commentId) : this.comments
    },
    dataLength() {
      return this.isSub ? this.getSubCommentsLength(this.commentId) : this.commentsLength
    },
  },
  mounted() {
    this.cacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.COMMENTS,
      cacheHandlers: [
        (data) => {
          this.setComments(data ?? [])
        },
      ],
    })
  },
  methods: {
    ...mapActions('Comments', ['getComments']),
    ...mapMutations('Comments', [
      'setIsReplyComment',
      'resetComments',
      'setComments',
      'setSelectedCommentParams',
    ]),
    ...mapMutations('Page', ['setPageBack']),
    async onGetComments(scroll) {
      const params = {
        post_id: this.postId,
        parent_id: this.isSub ? this.commentId : null,
        'not_exists[parent]': !this.isSub,
      }
      await this.getComments({
        scroll,
        isSub: this.isSub,
        params,
      })
    },
    onReply(reply) {
      this.setIsReplyComment(true)
      this.reply = reply
    },
    onSend() {
      if (this.reply) {
        this.onExpand(this.reply.id)
      } else {
        this.$nextTick(() => {
          this.$refs.content.scrollTo({ top: 0, behavior: 'smooth' })
        })
      }
      this.onCancel()
    },
    onCancel() {
      this.reply = null
      this.setIsReplyComment(false)
    },
    onExpand(id) {
      setTimeout(() => {
        this.$nextTick(() => {
          if (id) {
            const targetElement = document.getElementById(`sub-comments${id}`)
            const rect = targetElement?.getBoundingClientRect() ?? null
            const scrollTop = rect ? rect.top + this.$refs.content.scrollTop : null
            if (scrollTop) {
              this.$refs.content.scrollTo({
                top: scrollTop - 200,
                behavior: 'smooth',
              })
            }
          }
        })
      }, 1000)
    },
  },
})
</script>
<style lang="scss" module>
.section {
  color: $secondary;
  &.main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: calc(100% - #{$header});
    min-height: unset;
    margin-top: $header;
    overflow: hidden;
    background-color: #fff;
  }

  &.ios {
    padding-bottom: 18px;
  }
}

.content {
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;

  &.main {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.loader {
  margin: 0 auto;
}

.more {
  display: block;
  width: auto;
  margin: 2px auto 9px;
  border: 1px solid $secondary !important;
}

.overlay {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
}
</style>
