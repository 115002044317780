<template>
  <div :class="$style.expansions">
    <v-button
      v-if="!noButton"
      color="transparent-light"
      :class="[$style.button, className]"
      :size="size"
      :is-disabled="isDisabled"
      :is-disabled-padding="true"
      @click.stop="$emit('click')"
    >
      <span :class="$style.name">
        <slot name="name" />
      </span>
      <template v-if="!noArrow">
        <svg
          v-if="iconSize === 'sm'"
          :class="[$style.icon, $style[color], isOpen && $style.iconTransform]"
          width="7"
          height="4"
          viewBox="0 0 7 4"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- eslint-disable -->
          <path
            d="M6.85352 0.853516L3.85352 3.85352C3.75586 3.95117 3.62793 4 3.5 4C3.37207 4 3.24414 3.95117 3.14648 3.85352L0.146484 0.853516C-0.0488281 0.658203 -0.0488281 0.341797 0.146484 0.146484C0.341797 -0.0488281 0.658203 -0.0488281 0.853516 0.146484L3.5 2.79297L6.14648 0.146484C6.3418 -0.048828 6.6582 -0.048828 6.85352 0.146484C7.04883 0.341797 7.04883 0.658203 6.85352 0.853516Z"
          />
          <!-- eslint-enable -->
        </svg>
        <svg
          v-else
          :class="[$style.icon, $style[color], isOpen && $style.iconTransform]"
          width="12"
          height="6"
          viewBox="0 0 12 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <!-- eslint-disable -->
          <path
            d="M5.66377 5.87L0.16358 0.870068C0.0551777 0.771436 -5.9334e-09 0.635696 0 0.499956C5.25042e-09 0.37984 0.0429707 0.259237 0.129888 0.163535C0.315929 -0.0405639 0.631858 -0.0557003 0.836455 0.129844L6.00021 4.82411L11.164 0.129844C11.3686 -0.0556999 11.6845 -0.0405633 11.8705 0.163535C12.0551 0.367146 12.041 0.685012 11.8368 0.870068L6.33665 5.87C6.14573 6.04333 5.8547 6.04333 5.66377 5.87Z"
            fill="black"
          />
          <!-- eslint-enable -->
        </svg>
      </template>
    </v-button>
    <slot />
  </div>
</template>
<script>
import Vue from 'vue'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VExpansion',
  components: { VButton },
  props: {
    color: String,
    className: String,
    size: {
      default: 'lg',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].includes(v),
      type: String,
    },
    iconSize: {
      default: 'sm',
      validator: (v) => ['sm', 'lg'].includes(v),
      type: String,
    },
    isOpen: {
      require: true,
      type: Boolean,
    },
    isDisabled: Boolean,
    noArrow: Boolean,
    noButton: Boolean,
  },
})
</script>
<style lang="scss" module>
.expansions {
  position: relative;
  z-index: 1;
  display: grid;
  width: 100%;
  min-width: 100px;
  user-select: none;
  row-gap: 10px;
}

.button {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  column-gap: 10px;
  border-radius: 0 !important;

  &:hover {
    transform: scale(1);
    opacity: 0.7;
  }
}

.icon {
  align-self: center;
  justify-self: flex-start;
  transform: rotate(0);
  transition: transform 0.5s;
  fill: $secondary;

  &Transform {
    transform: rotate(-180deg);
  }
}

.name {
  align-self: center;
  justify-self: flex-start;
  width: 100%;
}
</style>
