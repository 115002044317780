<template>
  <v-expansion
    :class-name="`${$style.padding} ${className}`"
    :size="size"
    :icon-size="iconSize"
    :is-open="isOpen"
    :is-disabled="isDisabled"
    @click="onClick"
    :no-button="noButton"
  >
    <template #name>
      <slot name="name" />
    </template>
    <transition name="height-kit">
      <div v-if="isOpen" :class="$style[size]">
        <slot />
      </div>
    </transition>
  </v-expansion>
</template>
<script>
import Vue from 'vue'

import VExpansion from '@layouts/expansions/VExpansion.vue'

export default Vue.extend({
  name: 'ContentExpansion',
  components: { VExpansion },
  props: {
    size: String,
    iconSize: String,
    className: String,
    isDisabled: Boolean,
    noArrow: Boolean,
    noButton: Boolean,
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.value,
    }
  },
  watch: {
    value() {
      this.isOpen = this.value
    },
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen
      this.$emit('click', this.isOpen)
    },
  },
})
</script>
<style lang="scss" module>
.padding {
  padding: 10px;
}

.sm {
  font-size: 10px;
  line-height: 14px;
}

.md {
  font-size: 12px;
  line-height: 16px;
}

.lg {
  font-size: 14px;
  line-height: 18px;
}

.xl {
  font-size: 16px;
  line-height: 20px;
}
</style>
