<template>
  <Comments v-if="canShow" :is-sub="false" />
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Comments from '@components/layouts/comments/VComments.vue'

export default Vue.extend({
  name: 'PostComments',

  components: {
    Comments,
  },

  computed: {
    ...mapGetters('Post', ['post']),
    ...mapGetters('Comments', ['commentsSourceId']),
    ...mapGetters('Page', ['pageBack']),
    currentPostId() {
      return this.post?.id ?? null
    },
    paramsPostId() {
      return Number(this.$route.params.id ?? 0)
    },
    canShow() {
      return !!this.post && this.isCurrentSource
    },
    isCurrentSource() {
      return this.commentsSourceId === this.currentPostId
    },
  },

  created() {
    if (!this.canShow) {
      this.init()
    }
  },

  methods: {
    ...mapActions('Post', ['getPost']),
    ...mapMutations('Comments', ['resetComments', 'setSourceId']),
    ...mapMutations('Page', ['setPageBack']),
    async init() {
      this.resetComments()
      if (!this.post) {
        await this.getPost(this.paramsPostId)
        this.setPageBackToPost()
      }
      this.setSourceId(this.paramsPostId)
    },
    setPageBackToPost() {
      this.setPageBack({
        isDisabled: false,
        route: { name: 'Post', params: { id: this.paramsPostId } },
      })
    },
  },
})
</script>

<style scoped></style>
