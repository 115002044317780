// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VExpansion_expansions_3ejT1{position:relative;z-index:1;min-width:100px;-webkit-user-select:none;-moz-user-select:none;user-select:none;row-gap:10px}.VExpansion_button_2XSg3,.VExpansion_expansions_3ejT1{display:grid;width:100%}.VExpansion_button_2XSg3{grid-template-columns:1fr auto;justify-content:space-between;text-align:left;-moz-column-gap:10px;column-gap:10px;border-radius:0!important}.VExpansion_button_2XSg3:hover{transform:scale(1);opacity:.7}.VExpansion_icon_3c02V{align-self:center;justify-self:flex-start;transform:rotate(0);transition:transform .5s;fill:var(--supster-secondary)}.VExpansion_iconTransform_2Y2xO{transform:rotate(-180deg)}.VExpansion_name_2rcoL{align-self:center;justify-self:flex-start;width:100%}", ""]);
// Exports
exports.locals = {
	"expansions": "VExpansion_expansions_3ejT1",
	"button": "VExpansion_button_2XSg3",
	"icon": "VExpansion_icon_3c02V",
	"iconTransform": "VExpansion_iconTransform_2Y2xO",
	"name": "VExpansion_name_2rcoL"
};
module.exports = exports;
