// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VComments_section_2H_PL{color:var(--supster-secondary)}.VComments_section_2H_PL.VComments_main_2kyLg{position:fixed;top:0;left:0;z-index:1000;display:grid;grid-template-rows:1fr auto;width:100%;height:calc(100% - 47px);min-height:unset;margin-top:47px;overflow:hidden;background-color:#fff}.VComments_section_2H_PL.VComments_ios_2Pcd3{padding-bottom:18px}.VComments_content_3PQ58{display:grid;grid-auto-rows:min-content;grid-auto-flow:row}.VComments_content_3PQ58.VComments_main_2kyLg{overflow-x:hidden;overflow-y:scroll}.VComments_loader_2yboU{margin:0 auto}.VComments_more_2mnw2{display:block;width:auto;margin:2px auto 9px;border:1px solid var(--supster-secondary)!important}.VComments_overlay_21uWB{position:absolute;z-index:999;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"section": "VComments_section_2H_PL",
	"main": "VComments_main_2kyLg",
	"ios": "VComments_ios_2Pcd3",
	"content": "VComments_content_3PQ58",
	"loader": "VComments_loader_2yboU",
	"more": "VComments_more_2mnw2",
	"overlay": "VComments_overlay_21uWB"
};
module.exports = exports;
