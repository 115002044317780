<template>
  <div :class="$style.wrapper">
    <div
      :class="[
        $style.section,
        isMainComment && $style.main,
        isSub && $style.sub,
        isShow && $style.show,
        isOwnComment && $style.owner,
        !isOwnComment && $style.notowner,
        isSelected && $style.selected,
      ]"
      @click.stop="onOpenControls"
    >
      <VAvatar :src="src" :srcset="srcset" />
      <div>
        <div :class="$style.content">
          <p :class="$style.text">
            <span :class="$style.name">{{ name || $t('anonymous') }}:</span>
            {{ body }}
          </p>
          <div :class="$style.footer">
            <p :class="$style.date">
              <VDateGap :date="date" />
            </p>
            <VLike
              v-if="!isMainComment"
              :class="$style.like"
              color="transparent"
              icon-color="default"
              is-disabled-padding
              :count="likeCount"
              :is-loading="isLikeLoading"
              :is-liked="isLiked"
              @like="onLike"
            />
            <VButton
              v-if="!isMainComment && !isSub"
              size="md"
              color="transparent"
              is-disabled-padding
              @click="onReply"
            >
              {{ $t('reply') }}
            </VButton>
          </div>
        </div>
        <span
          :class="$style['expand-btn']"
          @click.stop="onExpand"
          v-if="comment.comment_count > 0"
          key="sub"
        >
          <icon
            :class="[$style.icon, isShow && $style.reverse]"
            width="7px"
            height="7px"
            name="back"
          />
          <span>
            {{ isShow ? this.$t('hide') : this.$t('show') }}
            <strong>{{ expansionText }}</strong>
          </span>
        </span>
      </div>
      <div :class="$style.buttons">
        <v-button
          v-if="!isSub && !isOwnComment"
          @click="onReply"
          size="md"
          color="primary"
          is-disabled-padding
        >
          <icon name="reply" />
        </v-button>
        <v-button
          v-if="!isOwnComment"
          @click.prevent.stop="onReport"
          size="md"
          color="black-primary-light"
          is-disabled-padding
        >
          <icon :class="$style['btn-icon-report']" name="warning" />
        </v-button>
        <v-button v-else @click.prevent.stop="onEdit" size="md" color="primary" is-disabled-padding>
          <icon name="edit-outline" />
        </v-button>
        <v-button
          v-if="canBeDeleted"
          @click.prevent.stop="deleteConfirm = true"
          size="md"
          color="black-primary-light"
          is-disabled-padding
        >
          <icon :class="$style['btn-icon-delete']" name="trash" />
        </v-button>
      </div>
    </div>
    <transition v-if="isShow" name="fade" mode="out-in">
      <ContentExpansion
        v-if="!isMainComment && !isSub"
        size="md"
        :id="`sub${comment.id}`"
        :value="isShow"
        :class-name="$style.expansion"
        no-button
      >
        <transition-group name="fade">
          <VComments
            is-sub
            :comment-id="comment.id"
            :id="`sub-comments${comment.id}`"
            :key="`sub-comments${comment.id}`"
            :sub-comments-count="comment.comment_count"
          />
        </transition-group>
      </ContentExpansion>
    </transition>
    <ConfirmModal
      :is-visible.sync="deleteConfirm"
      :title="$t('confirmation')"
      @confirm="onDelete"
      @close="onClose"
      @cancel="onClose"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VLike from '@layouts/like/VLike.vue'
import VButton from '@elements/VButton.vue'
import VDateGap from '@layouts/VDateGap.vue'
import ContentExpansion from '@layouts/expansions/list/ContentExpansion.vue'
import ConfirmModal from '@modals/list/ConfirmModal.vue'
import { abbreviateNumber } from '@utils/numbers'

import { MessageMode } from '@common-types/chat/chat-message'

export default Vue.extend({
  name: 'VComment',
  components: {
    VComments: () => import('@layouts/comments/VComments.vue'),
    VAvatar,
    VLike,
    VButton,
    ContentExpansion,
    VDateGap,
    ConfirmModal,
  },
  data() {
    return {
      deleteConfirm: false,
      isLikeLoading: false,
    }
  },
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
    isSub: Boolean,
    isMainComment: Boolean,
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Post', [
      'postAuthorAvatar',
      'postBody',
      'postCreated',
      'postAppName',
      'postBody',
    ]),
    ...mapGetters('Comments', [
      'selectedCommentParams',
      'getThreadVisibility',
      'selectedComment',
      'getIsSelectedComment',
    ]),
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('App', ['appAvatarSrc', 'appAvatarSrcset', 'appName']),
    isShow() {
      return this.getThreadVisibility(this.comment.id)
    },
    name() {
      return this.isMainComment ? this.postAppName : this.regularCommentName ?? null
    },
    regularCommentName() {
      return this.isModeApp ? this.appName(this.commentOwnerApp) : this.comment.client?.name
    },
    body() {
      return this.isMainComment ? this.postBody : this.comment.text
    },
    src() {
      return this.isMainComment
        ? this.postAuthorAvatar('content_url')
        : this.regularCommentAvatarSrc ?? null
    },
    regularCommentAvatarSrc() {
      return this.isModeApp
        ? this.appAvatarSrc(this.commentOwnerApp)
        : this.comment.client?.avatar?.content_url
    },
    commentOwnerApp() {
      return this.comment?.mode_app
    },
    srcset() {
      return this.isMainComment
        ? this.postAuthorAvatar('preview_url')
        : this.regularCommentAvatarSrcset ?? null
    },
    regularCommentAvatarSrcset() {
      return this.isModeApp
        ? this.appAvatarSrcset(this.commentOwnerApp)
        : this.comment.client?.avatar?.preview_url
    },
    date() {
      return this.isMainComment ? this.postCreated : this.comment.updated ?? this.comment.created
    },
    isLiked() {
      return this.comment.is_liked ?? false
    },
    likeCount() {
      return this.comment.like_count ?? 0
    },
    expansionText() {
      return `${this.$tc('replies', abbreviateNumber(this.comment.comment_count, this.lang))}`
    },
    isOwnComment() {
      return this.selectedComment?.client?.id === this.clientId
    },
    canBeDeleted() {
      return this.isOwnComment || this.isClientOwner
    },
    isSelected() {
      return this.getIsSelectedComment(this.comment.id)
    },
    isModeApp() {
      return this.comment?.mode === MessageMode.APP
    },
  },
  methods: {
    ...mapMutations('Comments', [
      'setThreadIsVisible',
      'setSelectedCommentParams',
      'setThreadProperties',
    ]),
    ...mapActions('Comments', ['setCommentLike']),
    ...mapActions('Comments', ['deleteComment']),
    ...mapMutations('Drawers', ['setDrawer']),
    async onLike() {
      this.isLikeLoading = true
      await this.setCommentLike({
        isLike: !this.isLiked,
        id: this.comment.id,
        isSub: this.isSub,
      })
      this.isLikeLoading = false
    },
    onReply() {
      this.setSelectedCommentParams(null)
      this.$emit('reply', this.comment)
    },
    onExpand() {
      this.$emit('expand', this.comment.id)
      if (this.isShow) {
        this.setThreadProperties({
          parentId: this.comment.id,
          isVisible: false,
        })
      } else {
        this.setThreadIsVisible(this.comment.id)
      }
    },
    onOpenControls() {
      if (this.isMainComment) {
        return
      }
      if (this.isSelected) {
        this.onClose()
        return
      }
      this.setSelectedCommentParams({
        commentId: this.comment.id,
        parentId: this.comment.parent?.id ?? null,
      })
    },
    onClose() {
      this.setSelectedCommentParams(null)
    },
    onDelete() {
      this.deleteComment()
    },
    onEdit() {
      this.setDrawer({ name: 'comment-edit', isVisible: true })
    },
    onReport() {
      this.setDrawer({ name: 'comment-report', isVisible: true })
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: grid;
  grid-template-columns: 36px 1fr;
  align-items: flex-start;
  padding: 12px;
  column-gap: 12px;
  box-shadow: inset 0 1px 0 $secondary-light;
  transition: all $transition ease-in;
  &.show.notsub {
    box-shadow: none;
  }
  &.owner {
    &.selected {
      transform: translateX(-96px);
      &.sub {
        transform: translateX(-105px);
      }
    }
  }
  &.notowner {
    &.selected {
      transform: translateX(-96px);
      &.sub {
        transform: translateX(-54px);
      }
    }
  }

  &.main {
    box-shadow: inset 0 -1px 0 $secondary;
  }

  &.sub {
    margin-right: -12px;
    padding: 9px 25px 9px 60px;
  }
  .icon {
    margin-right: 9px;
    transform: rotate(-90deg);
    transition: all $transition ease-in;
    &.reverse {
      transform: rotate(-270deg);
    }
  }

  &.selected {
    // background: $grey;
    transform: translateX(-96px);
  }
}

.btn-icon-delete,
.btn-icon-report {
  path {
    fill: $primary-dark-background;
  }
}

.expand-btn {
  padding: 5px 8px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 5px;
  cursor: pointer;
  strong {
    font-weight: 500;
  }

  &:hover {
    background: $grey;
  }
}

.buttons {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 10;
  display: flex;
  height: 100%;
  button {
    width: 48px;
    border-radius: 0 !important;
    cursor: pointer;
    svg {
      width: 18px;
    }
  }
}

.text {
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  white-space: normal;
  overflow-wrap: break-word;

  & .name {
    font-weight: 500;
  }
}

.content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 3px;
}

.footer {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  column-gap: 4px;

  & .date {
    align-self: center;
    color: $secondary;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.4;
  }

  & .like {
    padding: 0 5px 0 2px !important;
  }
}

.expansion {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
}
</style>
