// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VCommentInput_container_7Pjtq{position:relative;width:100%;height:100%}.VCommentInput_content_2m-ou{grid-template-columns:42px 1fr;-moz-column-gap:9px;column-gap:9px;background:#fff}.VCommentInput_content_2m-ou,.VCommentInput_reply_2rFNN{display:grid;padding:12px;box-shadow:inset 0 1px 0 #e5e3e3}.VCommentInput_reply_2rFNN{grid-template-columns:repeat(2,max-content);align-items:center;justify-content:space-between;width:100%;background:#f8f8f8}.VCommentInput_reply_2rFNN .VCommentInput_text_1_2It{font-weight:400;font-size:12px}.VCommentInput_reply_2rFNN .VCommentInput_text_1_2It .VCommentInput_name_lA4JA{font-weight:500}.VCommentInput_reply_2rFNN .VCommentInput_cancel_hZd6n{display:grid;grid-template-columns:repeat(2,auto);-moz-column-gap:6px;column-gap:6px}.VCommentInput_reply_2rFNN .VCommentInput_cancel_hZd6n .VCommentInput_icon_1eiUt{align-self:center;width:12px;height:12px}.VCommentInput_avatar_s_iY4{align-self:center;width:42px;height:42px;-o-object-fit:cover;object-fit:cover;border-radius:100%}", ""]);
// Exports
exports.locals = {
	"container": "VCommentInput_container_7Pjtq",
	"content": "VCommentInput_content_2m-ou",
	"reply": "VCommentInput_reply_2rFNN",
	"text": "VCommentInput_text_1_2It",
	"name": "VCommentInput_name_lA4JA",
	"cancel": "VCommentInput_cancel_hZd6n",
	"icon": "VCommentInput_icon_1eiUt",
	"avatar": "VCommentInput_avatar_s_iY4"
};
module.exports = exports;
