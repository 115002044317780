// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContentExpansion_padding_1AYE0{padding:10px}.ContentExpansion_sm_3TQNO{font-size:10px;line-height:14px}.ContentExpansion_md_3mtVA{font-size:12px;line-height:16px}.ContentExpansion_lg_2uXKc{font-size:14px;line-height:18px}.ContentExpansion_xl_zhMEa{font-size:16px;line-height:20px}", ""]);
// Exports
exports.locals = {
	"padding": "ContentExpansion_padding_1AYE0",
	"sm": "ContentExpansion_sm_3TQNO",
	"md": "ContentExpansion_md_3mtVA",
	"lg": "ContentExpansion_lg_2uXKc",
	"xl": "ContentExpansion_xl_zhMEa"
};
module.exports = exports;
